<template>
    <div class="fixed bg-[rgba(0,0,0,0.6)] w-full h-full z-[70] top-0 right-0" v-show="openEvent" @click="hiddenNotificationEvent">
    </div>
    <transition name="fade">
        <div class="sidebar absolute top-0 w-[320px] md:w-[420px] bg-white overflow-auto h-100 flex flex-col !duration-300 !ease-in-out	"
             :class="[openEvent ? 'right-0' : '-right-[320px] md:-right-[420px]']">

            <NotificationList @handleToggle="hiddenNotificationEvent" />

        </div>
    </transition>
</template>

<script setup>
import GenericButton from "@/Components/GenericButton.vue";
import { onMounted, ref } from "vue";
import NotificationList from "./NotificationList.vue";

const modalOpen = ref(false)
const props=defineProps({
    openEvent: {
        type: Boolean,
        default: false
    },

});
const components = {
    NotificationList,
};
const emit = defineEmits(['updateNotificationClose']);

const hiddenNotificationEvent = () => {
    emit('updateNotificationClose');
};

const toggleModal = () => {
    modalOpen.value = !modalOpen.value
}
onMounted( () => {
    console.log("NS mount");
});
</script>

<style scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999; /* Ensure it covers the sidebar */
    display: none; /* Hide by default */
}

.sidebar {
    position: fixed;
    top: 0;
    width: 320px;
    height: 100%;
    background-color: #fff;
    transition: right 1s; /* Sidebar transition */
    z-index: 1000;
}

.sidebar-open {
    right: 0; /* Display when 'open' is true */
}

/* Define sidebar transition */
.sidebar-enter-active, .sidebar-leave-active {
    transition: right 1s;
}
.sidebar-enter, .sidebar-leave-to {
    right: -320px; /* Initial and final positions */
}
@media (min-width:768px){
.sidebar{
	width: 420px;
}
.sidebar-enter, .sidebar-leave-to {
    right: -420px; /* Initial and final positions */
}
}

.calendar-container {
    width: 100%;
    height: 800px;
}

.is-edit-icon {
    display: none !important;
}
</style>
