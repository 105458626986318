<script setup>
import Icon from "@/Icons/jobs-icons.vue";
import GenericButton from "@/Components/GenericButton.vue";
import Avatar from "@/Components/Avatar.vue";
import {router, usePage} from "@inertiajs/vue3";
import moment from "moment/moment.js";

const props = defineProps({
    notification: Object,
});
const authUserPermissions = usePage().props.auth.permissions;
const emits = defineEmits(['handleToggle', 'previewFile']);

const formattedDate = (date) => {
    return moment(date).format('DD MMM YYYY');
}
const goToChat = (groupType, jobSlug, applicationId, message_id, groupId) => {
    if (authUserPermissions.length !== 0 && (groupType === 'hr_collaboration' || groupType === 'hr_communication') && jobSlug && applicationId ) {
        let pageTab = groupType === 'hr_collaboration' ? 'collaboration' : 'communication';
        router.get(route('communications.view', {
            job: jobSlug,
            application: applicationId
        }), {
            page: pageTab,
            message_id: message_id
        }, {preserveScroll: true});

    } else {
        router.get(route('chat.view', groupId))
    }
    axios.put(route('notifications.markAsRead', props.notification.id))
        .then(response => {
            emits('handleToggle');
        }).catch(error => {
            console.log(error);
        });
}
const previewFile = (src, type, attachmentId) => {
    emits('previewFile', { src, type, attachmentId });
}
const goToLink = (link) => {
    router.get(link);
    emits('handleToggle');
}

const clearMention = (message) => {
    return message.replace(/<a[^>]+>(.*?)<\/a>/g, '<span class="font-bold">$1</span>');
}


</script>

<template>
	<Avatar v-if="notification.type === 'application'" :user="notification.notifiable?.user" size="10" />
	<Avatar v-if="notification.type === 'job-application'"
		:user="{ ...notification.notifiable, profile_avatar: notification.notifiable.avatar_image }" size="10" />
	<Avatar v-if="notification.type === 'friend_request'" :user="notification?.data?.sender" size="10" />
	<Avatar v-if="notification.type === 'communication_chat_message'" :user="notification?.data?.sender" size="10" />
	<Avatar v-if="notification.type === 'mention'" :user="notification?.data?.sender" size="10" />
	<Avatar v-if="notification.type === 'post_comment'" :user="notification?.data?.sender" size="10" />
	<div class="flex-1">
		<p v-if="notification.type === 'application'">
			{{ $t('You have received a new application for the Job') }}:
			<a :href="`/communications/${notification?.data.job_slug}/${notification.notifiable?.id}`"
				class="underline text-main-lighter0">
				{{ notification.data.job_name }}
			</a>
		</p>
		<p v-if="notification.type === 'job-application'">
			<b>{{ notification?.data?.sender?.fullname }} </b> {{ notification?.data?.message }}
		</p>
		<p v-if="notification.type === 'friend_request'">
			<b>{{ notification?.data?.sender?.fullname }} </b> {{ notification?.data?.message }}
		</p>
		<span class="text-xs text-disabled" v-if="notification.type !== 'communication_chat_message'">{{
			formattedDate(notification?.created_at)
			}} - Communication</span>
		<div class="flex items-center justify-start gap-3 mt-2" v-if="notification?.data?.status === 'pending'">
			<GenericButton class="!py-3 !px-1" type="button" theme="inherit-contained" :text="$t('Accept')"
				@click="accepted(notification)" />
			<GenericButton class="!py-3 !px-1" type="button" theme="inherit-outlined" :text="$t('Decline')"
				@click="decline(notification)" />
		</div>
		<div v-if="notification.type === 'communication_chat_message'" class="flex flex-col w-full  gap-0.5">

			<div class="flex items-center w-full space-between">
				<p class="block font-sans antialiased font-light leading-relaxed text-inherit">
					<span class="font-semibold">{{ notification?.data?.sender?.fullname }} </span>
                    <span v-if="notification?.data?.message && !notification?.data?.mention">
                        sends: <span v-html="notification?.data?.message"></span>
                    </span>
                    <span v-else-if="notification?.data?.mention">
                        mentioned you in a message: <span v-html="clearMention(notification?.data?.message)"></span>
                    </span>
				</p>
				<div v-if="!notification.read_at" class="w-2.5 h-2.5 bg-[#00B8D9] rounded-full ml-auto"></div>
				<div v-if="notification.read_at" class="w-2.5 h-2.5 bg-transparent rounded-full ml-auto"></div>
			</div>
			<span class="text-xs text-disabled">{{ formattedDate(notification?.created_at) }}</span>
			<!-- <div class="flex w-full flex-col  gap-0.5" v-if="notification?.data?.attachmentId">
				<p class="block font-sans antialiased font-light leading-relaxed text-inherit">
					{{ notification.data.sender.fullname }} sent you a file
					<span class="font-semibold">{{ notification.data.attachment_name }}</span>
				</p>
				<div class="flex items-center justify-between w-full p-2 mt-3 bg-white border rounded-md cursor-pointer">
					<div @click="previewFile(
                              route('document.show-in-chat', [notification?.data.groupId, notification?.data.attachmentId, notification?.document.type]),
                              notification?.document.type,
                              notification?.document.encid
                           )">
						<Icon type="file" height="50" width="50" />
					</div>
					<div @click="previewFile(
                              route('document.show-in-chat', [notification?.data.groupId, notification?.data.attachmentId, notification?.document.type]),
                              notification?.document.type,
                              notification?.document.encid
                           )">
						<h4>{{ notification?.data?.attachment_name }}</h4>
					</div>
					<div>
						<a v-if="notification?.attachment?.id"
							:href="route('attachment.download', notification?.attachment?.id)" target="_blank"
							class="cursor-pointer hover:text-[#212B36] bg-white hover:border hover:border-[#919EAB52] items-center gap-2 rounded-lg px-3 py-1 font-bold leading-tight text-sm border border-secondary-10 text-secondary-8 bg-secondary-10">
							{{ $t('Download') }}</a>
					</div>
				</div>
			</div> -->
			<div class="mt-3" v-if="notification?.data?.message || notification?.data?.attachmentId">
				<GenericButton
					@click="goToChat(notification?.group_type, notification?.job?.slug, notification?.data?.application_id, notification?.data?.message_id, notification?.data?.groupId)"
					type="button" theme="inherit-contained"
					class="cursor-pointer hover:text-[#212B36] hover:bg-white hover:border hover:border-[#919EAB52] transition-all !py-1 !px-3"
					:text="$t('Reply')" />
			</div>

		</div>
		<div v-if="notification.type === 'mention'">
			<div>
				<b>{{ notification.data.sender.fullname }}</b> {{ notification.data.message }}
				<div class="mt-3" v-if="notification.data.link">
					<GenericButton @click="goToLink(notification.data.link)" type="link" theme="inherit-contained"
						class="cursor-pointer hover:text-[#212B36] hover:bg-white hover:border hover:border-[#919EAB52] transition-all !py-1 !px-3"
						:text="$t('Reply')" />
				</div>
			</div>
		</div>
		<div v-if="notification.type === 'post_comment'">
			<div>
				<b>{{ notification.data.sender.fullname }}</b> {{ $t(notification.data.message) }}
				<div class="mt-3" v-if="notification.data.link">
					<GenericButton @click="goToLink(notification.data.link)" type="link" theme="inherit-contained"
						class="cursor-pointer hover:text-[#212B36] hover:bg-white hover:border hover:border-[#919EAB52] transition-all !py-1 !px-3"
						:text="$t('open post')" />
				</div>
			</div>
		</div>
	</div>
</template>

<style>
.mention-link {
    color: rgba(5, 22, 178, 0.7);
    cursor: pointer;
    text-decoration: underline;
}
</style>
