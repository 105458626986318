<script setup>
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import IconAgenda from "@/Icons/agenda.vue";
import IconDown from "@/Icons/arrow-down.vue";
import IconCandidates from "@/Icons/candidates.vue";
import IconDashboard from "@/Icons/dashboard.vue";
import IconJobs from "@/Icons/jobs.vue";
import IconNewsFeed from "@/Icons/newsFeed.vue";
import IconProperty from "@/Icons/property.vue";
import IconAi from "@/Icons/ai.vue";

import {Head, Link, usePage} from "@inertiajs/vue3";
import {onMounted, ref} from "vue";
import {hasPermissions} from "@/Utils/permissions.js";
import {USER_PERMISSIONS} from "@/constants.js";

const activeUrl = ref(window.location.pathname);
const isJobListUrl = activeUrl.value.startsWith("/job-list/");
const isPropertyUrl = activeUrl.value.startsWith("/feed");
const jobLastPart = isJobListUrl ? activeUrl.value.split("/").pop() : "";

const showJobs = ref(isJobListUrl);
const showProperty = ref(isPropertyUrl);
const toggleJobs = () => {
    showJobs.value = !showJobs.value;
};
const toggleProperty = () => {
    showProperty.value = !showProperty.value;
};

const isActive = (url) => {
    return activeUrl.value === url;
};

const props = usePage().props;

const css = {
    bull_active: "w-2 h-2 bg-main rounded-full",
    bull: "w-1 h-1 bg-gray-500 rounded-full",
    label_active: "text-main font-semibold",
    label_active_sub: "text-black font-semibold",
    label: "text-secondary md:font-medium",
    bg_active: "md:!bg-main md:!bg-opacity-10 active",
    bg_inactive:
        "md:bg-white md:bg-opacity-0 transition-all md:hover:bg-grey-8",
};
const updateActiveUrl = (url) => {
    activeUrl.value = url;
};

const countUnreadMessages = ref(0);

const getUnreadMessages = () => {
    axios
        .get(route("chat.unread-messages-jobs"))
        .then((response) => {
            if (response.data.unreadMessagesCount > 0) {
                countUnreadMessages.value =
                    response.data.unreadMessagesCount > 99
                        ? "99+"
                        : response.data.unreadMessagesCount > 32
                            ? "32+"
                            : response.data.unreadMessagesCount;
            }
        })
        .catch((error) => {
            console.log(error);
        });
};
onMounted(() => {
    getUnreadMessages();
});
</script>

<template>
    <div
        class="w-full overflow-auto shrink-0 bottom-0 shadow-card md:shadow-none md:px-4 md:pb-10 bg-[rgba(255,255,255,0.7)] md:bg-white md:border-r md:border-gray-400 md:border-opacity-20 flex-col items-center inline-flex z-[60] md:transition-none fixed md:relative md:top-0 md:h-auto md:z-20 md:w-[280px] left-0 transition-all duration-300 backdrop-blur md:duration-0"
    >
        <div class="pt-6 px-4 pb-2.5 w-full hidden items-center md:flex">
            <ApplicationLogo class="max-w-[168px]"/>
        </div>
        <!-- <div class="self-stretch h-[69px] px-4 pt-6 pb-2  items-center flex">
            <div class="w-[168px] pb-[3px] justify-center items-center inline-flex">
                <img class="grow shrink basis-0 self-stretch max-w-[168px] max-h-[50px] object-contain" :src="$page.props.auth.organization?.avatar?.media1?.url || `/assets/img/company.png`
                    " :alt="$page.props.auth.organization?.name" />
              <img class="self-stretch grow shrink basis-0" src="https://via.placeholder.com/168x34" />
            </div>
        </div> -->
        <div
            class="flex items-center self-stretch gap-1 px-4 mx-auto md:w-full md:mx-0 w-fit md:items-start md:p-2 h-14 md:h-full md:justify-start md:flex-col md:grow md:shrink md:basis-0"
        >
            <div
                class="items-center self-stretch justify-start hidden pt-4 pb-2 pl-3 bg-white rounded-lg md:inline-flex"
            >
                <div
                    class="text-disabled text-[11px] font-bold uppercase leading-[18px]"
                >
                    {{ $t("Management") }}
                </div>
            </div>

            <!-- <Link
                :href="route('feed')"
                @click.prevent="updateActiveUrl('/')"
                :class="isActive('/') ? css.bg_active : css.bg_inactive"
                class="inline-flex flex-col items-center justify-start flex-shrink-0 transition-all md:w-full md:rounded-lg md:bg-white md:hover:bg-grey-8 min-w-16 md:max-w-none md:p-2 md:pl-3 md:pr-2 md:flex-row min-h-11 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="relative flex items-center justify-center w-6 h-6">
                        <IconNewsFeed />
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-[10px] md:text-sm"
                        :class="isActive('/') ? css.label_active : css.label"
                    >
                        {{ $t("News feed") }}
                    </div>
                </div>
            </Link> -->
            <!-- Property -->
            <Link
                v-if="hasPermissions(USER_PERMISSIONS.EDIT_COMPANY)"
                href="/property"
                @click.prevent="updateActiveUrl('/property')"
                :class="isActive('/property') ? css.bg_active : css.bg_inactive"
                class="inline-flex flex-col items-center justify-start flex-shrink-0 transition-all md:w-full md:rounded-lg md:bg-white md:hover:bg-grey-8 min-w-16 md:max-w-none md:p-2 md:pl-3 md:pr-2 md:flex-row min-h-11 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="relative flex items-center justify-center w-6 h-6">
                        <IconProperty/>
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-[10px] md:text-sm"
                        :class="
                            isActive('/property') ? css.label_active : css.label
                        "
                    >
                        {{ $t("Property") }}
                    </div>
                </div>
                <!-- <div class="flex items-center justify-center   group-[&.active]:rotate-180" @click.prevent="toggleProperty">
                <div class="relative w-4 h-4"  >
                    <IconDown />
                </div>
            </div> -->
            </Link>

            <!-- <Link href="/feed" @click="updateActiveUrl('/feed')" v-if="showProperty"
                :class="isActive('/feed') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 bg-white bg-opacity-0 rounded-lg min-h-11 group"
                @mouseover="hovering = true" @mouseout="hovering = false"
                :style="hovering ? { backgroundColor: 'rgba(0, 0, 255, 0.1)' } : {}">

            <div class="flex items-center justify-center md:pr-4">
                <div class="flex items-center justify-center w-6 h-6">
                    <div :class="isActive('/feed') ? css.bull_active : css.bull"></div>
                </div>
            </div>
            <div class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start">
                <div :class="isActive('/feed') ? css.label_active : css.label">{{ $t('News feed') }}</div>
            </div>

            </Link> -->
            <!-- END Property -->

            <Link
                href="/job-list/all"
                @click="updateActiveUrl('/job-list/all')"
                class="inline-flex flex-col items-center justify-start flex-shrink-0 transition-all md:w-full md:rounded-lg md:bg-white md:hover:bg-grey-8 min-w-16 md:max-w-none md:p-2 md:pl-3 md:pr-2 md:flex-row min-h-11 group"
                :class="
                    isActive('/job-list/all') ? css.bg_active : css.bg_inactive
                "
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="relative flex items-center justify-center w-6 h-6">
                        <!-- Jobs -->
                        <IconJobs/>
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-[10px] md:text-sm"
                        :class="
                            isActive('/job-list/all')
                                ? css.label_active
                                : css.label
                        "
                    >
                        {{ $t("Jobs") }}
                    </div>
                </div>
                <div
                    v-if="countUnreadMessages > 0"
                    class="w-fit px-1.5 py-1 bg-system-dark bg-opacity-20 rounded-md justify-start items-center gap-1.5 flex"
                >
                    <div
                        class="text-center text-system-dark text-xs font-bold font-[Montserrat] leading-tight"
                    >
                        {{ countUnreadMessages }}
                    </div>
                </div>
                <div
                    class="hidden md:flex items-center justify-center -rotate-90 group-[&.active]:rotate-180"
                    @click="toggleJobs"
                >
                    <div class="relative w-4 h-4">
                        <IconDown/>
                    </div>
                </div>
            </Link>

            <!-- Start Jobs sub -->
            <Link
                href="/job-list/active"
                @click="updateActiveUrl('/job-list/active')"
                v-if="showJobs"
                class="items-center self-stretch justify-start hidden pl-3 pr-2 transition-all bg-opacity-0 rounded-lg md:inline-flex hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="flex items-center justify-center w-6 h-6">
                        <div
                            :class="
                                isActive('/job-list/active')
                                    ? css.bull_active
                                    : css.bull
                            "
                        ></div>
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-sm"
                        :class="
                            isActive('/job-list/active')
                                ? css.label_active_sub
                                : css.label
                        "
                    >
                        {{ $t("Active") }}
                    </div>
                </div>
            </Link>

            <Link
                href="/job-list/draft"
                @click="updateActiveUrl('/job-list/draft')"
                v-if="showJobs"
                class="items-center self-stretch justify-start hidden pl-3 pr-2 transition-all bg-opacity-0 rounded-lg md:inline-flex hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="flex items-center justify-center w-6 h-6">
                        <div
                            :class="
                                isActive('/job-list/draft')
                                    ? css.bull_active
                                    : css.bull
                            "
                        ></div>
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-sm"
                        :class="
                            isActive('/job-list/draft')
                                ? css.label_active_sub
                                : css.label
                        "
                    >
                        {{ $t("Drafts") }}
                    </div>
                </div>
            </Link>

            <!-- <Link href="/job-list/deleted" @click="updateActiveUrl('/job-list/deleted')" v-if="showJobs"
                class="inline-flex items-center self-stretch justify-start pl-3 pr-2 transition-all bg-white bg-opacity-0 rounded-lg hover:bg-grey-8 group">
            <div class="flex items-center justify-center md:pr-4">

                <div class="flex items-center justify-center w-6 h-6">
                    <div :class="isActive('/job-list/deleted') ? css.bull_active : css.bull"></div>
                </div>
            </div>
            <div class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start">
                <div :class="isActive('/job-list/deleted') ? css.label_active : css.label">{{ $t('Deleted') }}</div>
            </div>
            </Link> -->

            <Link
                href="/job-list/archived"
                @click="updateActiveUrl('/job-list/archived')"
                v-if="showJobs"
                class="items-center self-stretch justify-start hidden pl-3 pr-2 transition-all bg-opacity-0 rounded-lg md:inline-flex hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="flex items-center justify-center w-6 h-6">
                        <div
                            :class="
                                isActive('/job-list/archived')
                                    ? css.bull_active
                                    : css.bull
                            "
                        ></div>
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-sm"
                        :class="
                            isActive('/job-list/archived')
                                ? css.label_active_sub
                                : css.label
                        "
                    >
                        {{ $t("Archived") }}
                    </div>
                </div>
            </Link>
            <Link
                href="/job-list/duplicate"
                @click="updateActiveUrl('/job-list/duplicate')"
                v-if="showJobs && hasPermissions([USER_PERMISSIONS.HR])"
                class="items-center self-stretch justify-start hidden pl-3 pr-2 transition-all bg-opacity-0 rounded-lg md:inline-flex hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="flex items-center justify-center w-6 h-6">
                        <div
                            :class="
                                isActive('/job-list/duplicate')
                                    ? css.bull_active
                                    : css.bull
                            "
                        ></div>
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-sm"
                        :class="
                            isActive('/job-list/duplicate')
                                ? css.label_active_sub
                                : css.label
                        "
                    >
                        {{ $t("Duplicate Jobs") }}
                    </div>
                </div>
            </Link>

            <!-- End Jobs -->

            <!-- <Link href="/candidates" @click="updateActiveUrl('/candidates')"
                :class="isActive('/candidates') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-none hover:bg-grey-8 group">
            <div class="flex items-center justify-center md:pr-4">
                <div class="relative flex items-center justify-center w-6 h-6">
                    <IconCandidates />
                </div>
            </div>
            <div class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start">
                <div :class="isActive('/candidates') ? css.label_active : css.label">{{ $t('Candidates') }}</div>
            </div>
            </Link> -->

            <!-- <Link :href="route('agendas.index', 'hr')" @click.prevent="updateActiveUrl('/agenda/hr')"
                :class="isActive('/agenda/hr') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-none rounded-lg group">
            <div class="flex items-center justify-center md:pr-4">
                <div class="relative flex items-center justify-center w-6 h-6">
                    <IconAgenda />
                </div>
            </div>
            <div class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start">
                <div :class="isActive('/agenda/hr') ? css.label_active : css.label">{{ $t('Agenda') }}</div>
            </div>
            </Link> -->


            <!-- <Link
                :href="route('events.index', 'super-admin')"
                @click.prevent="updateActiveUrl('/events/super-admin')"
                :class="
                    isActive('/events/super-admin')
                        ? css.bg_active
                        : css.bg_inactive
                "
                class="inline-flex flex-col items-center justify-start flex-shrink-0 transition-all md:w-full md:rounded-lg md:bg-white md:hover:bg-grey-8 min-w-16 md:max-w-none md:p-2 md:pl-3 md:pr-2 md:flex-row min-h-11 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="relative flex items-center justify-center w-6 h-6">
                        <IconAgenda />
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-[10px] md:text-sm"
                        :class="
                            isActive('/events/super-admin')
                                ? css.label_active
                                : css.label
                        "
                    >
                        {{ $t("Public Events") }}
                    </div>
                </div>
            </Link> -->
            <Link
                href="/dashboard"
                @click="updateActiveUrl('/dashboard')"
                :class="
                    isActive('/dashboard') ? css.bg_active : css.bg_inactive
                "
                class="inline-flex flex-col items-center justify-start flex-shrink-0 transition-all md:w-full md:rounded-lg md:bg-white md:hover:bg-grey-8 min-w-16 md:max-w-none md:p-2 md:pl-3 md:pr-2 md:flex-row min-h-11 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="relative flex items-center justify-center w-6 h-6">
                        <IconDashboard/>
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-[10px] md:text-sm"
                        :class="
                            isActive('/dashboard')
                                ? css.label_active
                                : css.label
                        "
                    >
                        {{ $t("Dashboard") }}
                    </div>
                </div>
            </Link>
            <Link
                v-if="hasPermissions(USER_PERMISSIONS.HR)"
                :href="route('ai')"
                @click.prevent="updateActiveUrl('/ai')"
                :class="isActive('/ai') ? css.bg_active : css.bg_inactive"
                class="inline-flex flex-col items-center justify-start flex-shrink-0 transition-all md:w-full md:rounded-lg md:bg-white md:hover:bg-grey-8 min-w-16 md:max-w-none md:p-2 md:pl-3 md:pr-2 md:flex-row min-h-11 group"
            >
                <div class="flex items-center justify-center md:pr-4">
                    <div class="relative flex items-center justify-center w-6 h-6">
                        <IconAi/>
                    </div>
                </div>
                <div
                    class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start"
                >
                    <div
                        class="text-[10px] md:text-sm"
                        :class="isActive('/ai') ? css.label_active : css.label"
                    >
                        {{ $t('Hospitaliti AI') }}
                    </div>
                </div>
            </Link>
            <!-- <Link v-if="props.isSuperUser"  :href="route('integrations.index')" @click="updateActiveUrl('/integrations')"
                :class="isActive('/integrations') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-none hover:bg-grey-8 group">
                <div class="flex items-center justify-center md:pr-4">
                    <div class="relative flex items-center justify-center w-6 h-6">
                        <IconCandidates />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center text-center md:pr-4 grow shrink basis-0 md:text-left md:items-start md:justify-start">
                    <div :class="isActive('/integrations') ? css.label_active : css.label">{{ $t('Integrations') }}</div>
                </div>
            </Link> -->
        </div>
    </div>
</template>
